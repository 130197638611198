import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import { fetchAdminUsers } from "../api";
import { IconUsers } from "@tabler/icons-react";

const relativeTimestamp = (epoch, isSub = false) => {
    if (isSub) {
        if (epoch === 2000000000000) {
            return "never";
        } else if (epoch < new Date().getTime()) {
            return "-";
        }
    }

    if (!epoch) {
        return "-";
    }

    const seconds = Math.floor((new Date(epoch) - new Date()) / 1000);
    const isFuture = seconds > 0;
    const absSeconds = Math.abs(seconds);
    let interval = Math.floor(absSeconds / 31536000);

    if (interval >= 1) {
        return isFuture ? `in ${interval} year${interval > 1 ? 's' : ''}` : `${interval} year${interval > 1 ? 's' : ''} ago`;
    }
    interval = Math.floor(absSeconds / 2592000);
    if (interval >= 1) {
        return isFuture ? `in ${interval} month${interval > 1 ? 's' : ''}` : `${interval} month${interval > 1 ? 's' : ''} ago`;
    }
    interval = Math.floor(absSeconds / 86400);
    if (interval >= 1) {
        return isFuture ? `in ${interval} day${interval > 1 ? 's' : ''}` : `${interval} day${interval > 1 ? 's' : ''} ago`;
    }
    interval = Math.floor(absSeconds / 3600);
    if (interval >= 1) {
        return isFuture ? `in ${interval} hour${interval > 1 ? 's' : ''}` : `${interval} hour${interval > 1 ? 's' : ''} ago`;
    }
    interval = Math.floor(absSeconds / 60);
    if (interval >= 1) {
        return isFuture ? `in ${interval} minute${interval > 1 ? 's' : ''}` : `${interval} minute${interval > 1 ? 's' : ''} ago`;
    }
    if (absSeconds < 60) {
        return "just now";
    }
    return isFuture ? `in ${Math.floor(absSeconds)} second${absSeconds > 1 ? 's' : ''}` : `${Math.floor(absSeconds)} second${absSeconds > 1 ? 's' : ''} ago`;
}

const Admin = () => {
    const ctx = useOutletContext();
    const navigate = useNavigate();

    const [adminData, setAdminData] = useState({});

    useEffect(() => {
        if (ctx.loaded && !ctx.user.is_admin) {
            navigate("/");
        }

        (async () => {
            if (ctx.loaded && !adminData.hasOwnProperty("users")) {
                await fetchAdminUsers(ctx.user.token, setAdminData);
            }
        })();
    }, [ctx]);

    if (!ctx.loaded) return <></>;

    return (
        <>
            <Dashboard />

            <div className="flex justify-start p-2 bg-gradient-to-b from-violet-600/10 via-transparent">
                <div className="w-full lg:ps-64">
                    <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
                        <div className="w-full h-full">
                            <h2 className="text-2xl font-bold md:leading-tight text-white">
                                <div className="flex items-center gap-2">
                                    <IconUsers size={36} />
                                    Users
                                </div>
                            </h2>
                            {adminData.hasOwnProperty("users") ? (
                                <div className="w-full max-w-full py-6">
                                    <div className="flex flex-col">
                                        <div className="-m-1.5 overflow-x-auto">
                                            <div className="p-1.5 min-w-full inline-block align-middle">
                                                <div className="border rounded-lg overflow-hidden dark:border-neutral-700">
                                                    <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                                                        <thead className="bg-gray-50 dark:bg-neutral-800">
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">ID</th>
                                                                <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">Email</th>
                                                                <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">Username</th>
                                                                <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">Subscription Expiry</th>
                                                                <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">Web Activity</th>
                                                                <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">Client Activity</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                                                            {adminData.users.map((user) => (
                                                                <tr key={user.id}>
                                                                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">{user.id}</td>
                                                                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">{user.email}</td>
                                                                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">{user.username}</td>
                                                                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">{relativeTimestamp(user.sub_ends_at, true)}</td>
                                                                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">{relativeTimestamp(user.authenticated_at)}</td>
                                                                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">{relativeTimestamp(user.client_authenticated_at)}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (<></>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Admin;
