import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IconApps,
  IconBuildingStore,
  IconCat,
  IconChevronDown,
  IconChevronRight,
  IconChevronUp,
  IconFileInfo,
  IconHome,
  IconInfoCircleFilled,
  IconLayoutSidebarRightCollapse,
  IconLogout,
} from "@tabler/icons-react";

const Dashboard = () => {
  const ctx = useOutletContext();
  const navigate = useNavigate();

  const SignOutDiscord = async () => {
    localStorage.removeItem("accessToken");
    ctx.setUser({});
    navigate("/");
  };

  if (!ctx.loaded) return <></>;

  return (
    <>
      <header className="sticky top-0 inset-x-0 flex flex-wrap md:justify-start md:flex-nowrap z-[48] w-full border-b text-sm py-2.5 lg:ps-[260px] bg-neutral-800 border-neutral-700">
        <nav className="px-4 sm:px-6 flex basis-full items-center w-full mx-auto">
          <div className="me-5 lg:me-0 lg:hidden">
            <button
              className="flex items-center gap-2 rounded-xl text-xl font-semibold focus:outline-none focus:opacity-80"
              onClick={() => navigate("/")}
            >
              <img src="/assets/logo.png" width={36} alt="eclypse logo" />
              <span className="p-2 text-xl bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                eclypse.cc
              </span>
            </button>
          </div>

          <div className="w-full flex items-center justify-end ms-auto md:justify-between gap-x-1 md:gap-x-3">
            <div className="hidden md:block"></div>

            <div className="flex flex-row items-center justify-end gap-1">
              <div className="hs-dropdown [--placement:bottom-right] relative inline-flex">
                <button
                  id="hs-dropdown-account"
                  type="button"
                  className="size-[38px] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent focus:outline-none disabled:opacity-50 disabled:pointer-events-none text-white"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  aria-label="Dropdown"
                >
                  <img
                    className="shrink-0 size-[38px] rounded-full"
                    src={`https://cdn.discordapp.com/avatars/${ctx.user.id}/${ctx.user.avatar}.png`}
                    alt="Avatar"
                  />
                </button>

                <div
                  className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 shadow-md rounded-lg mt-2 bg-neutral-800 border border-neutral-700 divide-neutral-700 after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="hs-dropdown-account"
                >
                  <div className="py-3 px-5 rounded-t-lg bg-neutral-700">
                    <p className="text-sm text-neutral-200">
                      Signed in as {ctx.user.global_name}
                    </p>
                    <p className="text-sm font-medium text-neutral-400">
                      {ctx.user.email}
                    </p>
                  </div>
                  <div className="p-1.5 space-y-0.5">
                    <button
                      className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm focus:outline-none text-neutral-400 hover:bg-neutral-700 hover:text-neutral-300 focus:bg-neutral-700 focus:text-neutral-300"
                      onClick={() => navigate("/")}
                    >
                      <IconHome size={18} />
                      Home
                    </button>
                    <button
                      className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-red-500 focus:outline-none hover:bg-neutral-700 focus:bg-neutral-700"
                      href="#"
                      onClick={SignOutDiscord}
                    >
                      <IconLogout size={18} />
                      Sign out
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className="-mt-px">
        <div className="sticky top-0 inset-x-0 z-20 border-y px-4 sm:px-6 lg:px-8 lg:hidden bg-neutral-800 border-neutral-700">
          <div className="flex items-center py-2">
            <button
              type="button"
              className="size-8 flex justify-center items-center gap-x-2 border rounded-lg focus:outline-nonedisabled:opacity-50 disabled:pointer-events-none border-neutral-700 text-neutral-200 hover:text-neutral-500 focus:text-neutral-500"
              aria-haspopup="dialog"
              aria-expanded="false"
              aria-controls="hs-application-sidebar"
              aria-label="Toggle navigation"
              data-hs-overlay="#hs-application-sidebar"
            >
              <span className="sr-only">Toggle Navigation</span>
              <IconLayoutSidebarRightCollapse size={20} />
            </button>
            <ol className="ms-3 flex items-center whitespace-nowrap">
              <li className="flex items-center text-sm text-neutral-400">
                Dashboard
                <IconChevronRight size={18} className="m-1" />
              </li>
              <li
                className="text-sm font-semibold truncate text-neutral-400"
                aria-current="page"
              >
                Subscriptions
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div
        id="hs-application-sidebar"
        className="hs-overlay  [--auto-close:lg]
  hs-overlay-open:translate-x-0
  -translate-x-full transition-all duration-300 transform
  w-[260px] h-full
  hidden
  fixed inset-y-0 start-0 z-[60]
  border-e
  lg:block lg:translate-x-0 lg:end-auto lg:bottom-0
  bg-neutral-800 border-neutral-700"
        role="dialog"
        tabIndex="-1"
        aria-label="Sidebar"
      >
        <div className="relative flex flex-col h-full max-h-full">
          <div className="px-6 pt-4">
            <button
              className="flex items-center gap-2 rounded-xl text-xl font-semibold focus:outline-none focus:opacity-80"
              onClick={() => navigate("/")}
            >
              <img src="/assets/logo.png" width={40} alt="eclypse logo" />
              <span className="p-2 text-xl bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                eclypse.cc
              </span>
            </button>
          </div>

          <div className="h-full overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-neutral-700 [&::-webkit-scrollbar-thumb]:bg-neutral-500">
            <nav
              className="hs-accordion-group p-3 w-full flex flex-col flex-wrap"
              data-hs-accordion-always-open
            >
              <ul className="flex flex-col space-y-1">
                <li>
                  <button
                    className="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-lg hover:bg-neutral-900 text-neutral-200 hover:text-neutral-300"
                    onClick={() => navigate("/subscriptions")}
                  >
                    <IconApps size={20} className="shrink-0" />
                    Subscriptions
                  </button>
                </li>

                <li>
                  <button
                    className="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-lg hover:bg-neutral-900 text-neutral-200 hover:text-neutral-300"
                    onClick={() => navigate("/store")}
                  >
                    <IconBuildingStore size={20} className="shrink-0" />
                    Store
                  </button>
                </li>

                {ctx.user.has_sub ? (
                  <li className="hs-accordion" id="users-accordion">
                    <button
                      type="button"
                      className="hs-accordion-toggle w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-lg hover:bg-neutral-900 text-neutral-200 hover:text-neutral-300"
                      aria-expanded="true"
                      aria-controls="users-accordion-child"
                    >
                      <IconFileInfo size={20} className="shrink-0" />
                      Documentation
                      <IconChevronUp
                        size={14}
                        className="hs-accordion-active:block ms-auto hidden size-4"
                      />
                      <IconChevronDown
                        size={14}
                        className="hs-accordion-active:hidden ms-auto block size-4"
                      />
                    </button>

                    <div
                      id="users-accordion-child"
                      className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden"
                      role="region"
                      aria-labelledby="users-accordion"
                    >
                      <ul
                        className="hs-accordion-group ps-8 pt-1 space-y-1"
                        data-hs-accordion-always-open
                      >
                        <li className="hs-accordion" id="users-accordion-sub-1">
                          <button
                            type="button"
                            className="hs-accordion-toggle w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-lg hover:bg-neutral-900 text-neutral-200 hover:text-neutral-300"
                            aria-expanded="true"
                            aria-controls="users-accordion-sub-1-child"
                          >
                            <img
                              src="/assets/cs2-logo.ico"
                              width={20}
                              alt="CS2 logo"
                            />
                            Counter-Strike 2
                            <IconChevronUp
                              size={14}
                              className="hs-accordion-active:block ms-auto hidden size-4"
                            />
                            <IconChevronDown
                              size={14}
                              className="hs-accordion-active:hidden ms-auto block size-4"
                            />
                          </button>

                          <div
                            id="users-accordion-sub-1-child"
                            className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden"
                            role="region"
                            aria-labelledby="users-accordion-sub-1"
                          >
                            <ul className="pt-1 space-y-1">
                              <li>
                                <button
                                  className="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-lg hover:bg-neutral-900 text-neutral-200 hover:text-neutral-300"
                                  onClick={() => navigate("/setup")}
                                >
                                  <IconInfoCircleFilled
                                    size={20}
                                    className="shrink-0"
                                  />
                                  How to setup
                                </button>
                              </li>
                              <li>
                                <button
                                  className="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-lg hover:bg-neutral-900 text-neutral-200 hover:text-neutral-300"
                                  onClick={() => navigate("/faq")}
                                >
                                  <IconInfoCircleFilled
                                    size={20}
                                    className="shrink-0"
                                  />
                                  FAQ
                                </button>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                ) : (
                  <></>
                )}
                {ctx.user.is_admin ? (
                  <li>
                    <button
                      className="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-lg hover:bg-neutral-900 text-neutral-200 hover:text-neutral-300"
                      onClick={() => navigate("/admin")}
                    >
                      <IconCat size={20} className="shrink-0" />
                      Admin
                    </button>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
