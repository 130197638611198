const API_URL = `https://eclypse.cc/api`;

export const fetchDiscordUser = async (setUser) => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
        const dcRequest = await fetch("https://discord.com/api/users/@me", {
            "headers": {
                "Authorization": `Bearer ${accessToken}`,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });

        const dcData = await dcRequest.json();

        if (dcData) {
            const apiRequest = await fetch(`${API_URL}/authenticate`, {
                "method": "POST",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                "body": JSON.stringify({
                    "discord_email": dcData.email,
                    "discord_user_id": dcData.id,
                    "discord_username": dcData.username,
                    "discord_access_token": accessToken
                })
            });

            const apiData = await apiRequest.json();

            if (apiData.success) {
                setUser({
                    ...dcData,
                    ...apiData
                });
            }
        }
    }
}

export const sendAnalytics = async (data) => {
    const apiRequest = await fetch(`${API_URL}/acs`, {
        "method": "POST",
        "headers": {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        "body": JSON.stringify({
            "data": btoa(JSON.stringify(data))
        })
    });

    await apiRequest.json();
}

export const fetchAdminUsers = async (token, setAdminData) => {
    const apiRequest = await fetch(`${API_URL}/admin/users?token=${token}`, {
        "method": "GET",
        "headers": {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    });

    const apiData = await apiRequest.json();

    if (apiData.success) {
        setAdminData(apiData);
    }
}